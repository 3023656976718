import { Scene } from "../../node_modules/three/src/scenes/Scene.js";
import { OrthographicCamera } from "../../node_modules/three/src/cameras/OrthographicCamera.js";
import { WebGLRenderTarget } from "../../node_modules/three/src/renderers/WebGLRenderTarget.js";
import { Mesh } from "../../node_modules/three/src/objects/Mesh.js";
import { BoxGeometry } from "../../node_modules/three/src/geometries/BoxGeometry.js";
import { BufferGeometry } from "../../node_modules/three/src/core/BufferGeometry.js";
import { Float32BufferAttribute, Uint16BufferAttribute, Uint32BufferAttribute } from "../../node_modules/three/src/core/BufferAttribute.js";
import BeyondRealityFaceMeshData from "./BeyondRealityFaceMeshData.js";
import { RawShaderMaterial, DoubleSide, VideoTexture, RGBFormat, LinearFilter } from "../../node_modules/three/src/Three.js";

export default class FaceUnwrapper {
  constructor({ renderer, cameraVideoTexture }) {
    this._renderer = renderer;
    
    this._scene = new Scene();
    this._camera = new OrthographicCamera(-.5, .5, .5, -.5);
    this._camera.position.z = 1;

    this._renderTarget = new WebGLRenderTarget(1024, 1024);

    this._faceGeometry = new BufferGeometry();
    this._faceGeometry.addAttribute("position", new Float32BufferAttribute(BeyondRealityFaceMeshData.positions, 2));
    this._faceGeometry.addAttribute("cameraVideoPosition", new Float32BufferAttribute(BeyondRealityFaceMeshData.positions, 2));
    this._faceGeometry.getAttribute("cameraVideoPosition").setDynamic(true);
    this._faceGeometry.setIndex(BeyondRealityFaceMeshData.indices);

    const mesh = new Mesh(this._faceGeometry, new RawShaderMaterial({
      side: DoubleSide,
      // wireframe: true,
      uniforms: {
        cameraVideoTexture: { value: cameraVideoTexture },
      },
      vertexShader: `
        attribute vec2 position;
        attribute vec2 cameraVideoPosition;

        varying vec2 vUv;
        
        void main() {
          vec2 position = position;
          position *= .999; // add 1px border
          gl_Position = vec4(position, 0., 1.);

          vec2 uv = cameraVideoPosition;
          uv.y = 1. - uv.y;
          vUv = uv;
        }
        `,
      fragmentShader: `
        precision highp float;
        
        uniform sampler2D cameraVideoTexture;
        
        varying vec2 vUv;

        void main() {
          gl_FragColor = vec4(vUv, 0., 1.);
          gl_FragColor = texture2D(cameraVideoTexture, vUv);
          // gl_FragColor.rgb = vec3(1.);
          gl_FragColor.a = 1.;
        }
      `,
    }));
    mesh.frustumCulled = false;

    this._scene.add(mesh);

    // this._scene.add(new Mesh(new BoxGeometry(.1, .1, .1)));
  }

  get texture() {
    return this._renderTarget.texture;
  }

  update({ 
    faceData,
    imageDataWidth = 640,
    imageDataHeight = 480,
  }) {
    // console.log(faceData);

    const cameraVideoPositionAttribute = this._faceGeometry.getAttribute("cameraVideoPosition");
    cameraVideoPositionAttribute.copyArray(faceData.vertices);
    const array = cameraVideoPositionAttribute.array;
    for (let index = 0; index < array.length * .5; index++) {
      // array[index * 2] = (array[index * 2] - faceData.bounds.x) / faceData.bounds.width;
      array[index * 2] /= imageDataWidth;
      // array[index * 2 + 1] = (array[index * 2 + 1] - faceData.bounds.y) / faceData.bounds.height;
      array[index * 2 + 1] /= imageDataHeight;
    }
    cameraVideoPositionAttribute.needsUpdate = true;

    // console.log(cameraVideoPositionAttribute.array);

    this._renderer.setRenderTarget(this._renderTarget);
    this._renderer.render(this._scene, this._camera);
    this._renderer.setRenderTarget(null);
  }
}
