import GUI from "../../node_modules/@damienmortini/graph/javascript/GUI.js";

import Scene from "./Scene.js";
import { WebGLRenderer } from "../../node_modules/three/src/Three.js";
import THREELoader from "../../node_modules/@damienmortini/three/loader/THREELoader.js";
import supportsWebAssembly from "./SupportsWebAssembly.js";

const COMPUTED_MEDIA_WIDTH = 640;
const COMPUTED_MEDIA_HEIGHT = 480;

let BRF_LOADED = false;
let BRF_V4;

GUI.hidden = !/\bgui\b/.test(window.location.search);

/**
 * Entry point element
 * @property {string} [baseURI = "./"] Base URI attribute, only needed if assets are incorrectly loaded (see example).
 * @property {boolean} [debugmesh] Display debug mesh on top
 * @property {boolean} [hd] Activate HD version
 * @property {boolean} [mirror] Mirror canvas (e.g. for front camera use)
 * @property {boolean} [nogreyhair] Remove grey hair filter
 * @property {"0"|"90"|"-90"} [camerarotation="0"] Set camera feed rotation. If rotated, screen OS needs to be in portrait mode
 * @example
 * <script type="module" src="build/index.js">
 *
 * <scottishwidows-faceaging mirror baseURI="assets/path/"></scottishwidows-faceaging>
 *
 * <script>
 *
 *   const scottishWidowsFaceAging = document.querySelector("scottishwidows-faceaging");
 *
 *   scottishWidowsFaceAging.age = 75;
 * 
 *   scottishWidowsFaceAging.snapshot();
 * 
 *   scottishWidowsFaceAging.addEventListener("error", (event) => {
 *     console.log(event.error);
 *   });
 *
 * </script>
 */

class ScottishWidowsFaceAgingElement extends HTMLElement {
  static get observedAttributes() {
    return ["mirror"];
  }

  constructor() {
    super();

    this._paused = true;
    this._age = 50;
    this.__faceDetected = false;

    this._updateBinded = this._update.bind(this);
    this._resizeBinded = this.resize.bind(this);

    this.attachShadow({ mode: "open" }).innerHTML = `
      <style>
        :host {
          display: block;
          position: relative;
        }
        
        canvas {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      </style>
      <canvas></canvas>
    `;
  }

  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case "mirror":
        if (this._scene) {
          this._scene.mirror = this.mirror;
        }
        break;
    }
  }

  connectedCallback() {
    const asmVariant = supportsWebAssembly() ? "wasm" : "asmjs";
    THREELoader.baseURI = this.getAttribute("baseURI") || "";
    const brfBase = `${THREELoader.baseURI}brf_${asmVariant}`;

    if (!BRF_LOADED) {
      const brfSource = `${brfBase}/BRFv4_JS_ST240819_v4.2.1_commercial.js`;

      const brfScript = document.createElement("script");
      brfScript.onload = () => {
        BRF_LOADED = true;
        this.connectedCallback();
      };
      brfScript.src = brfSource;
      document.head.appendChild(brfScript);
      return;
    }

    if (!BRF_V4) {
      BRF_V4 = {
        locateFile: (fileName) => {
          return `${THREELoader.baseURI}brf_${asmVariant}/${fileName}`;
        },
      };
      window.initializeBRF(BRF_V4);
    }

    Scene.load().then(() => {
      if (!this._canvas) {
        this._cameraRotation = Number(this.getAttribute("camerarotation")) || 0;
        this._timeoutId = 0;

        this._canvas = this.shadowRoot.querySelector("canvas");
        this._renderer = new WebGLRenderer({ canvas: this._canvas, alpha: false, antialias: true, preserveDrawingBuffer: true });
        // this._renderer.setPixelRatio(window.devicePixelRatio);

        this._snapshotCanvas = document.createElement("canvas");
        this._snapshotCanvasContext = this._snapshotCanvas.getContext("2d");
        this._snapshotCanvas.width = 1080;
        this._snapshotCanvas.height = 1080;

        this._mediaCanvas = document.createElement("canvas");
        this._mediaCanvasContext = this._mediaCanvas.getContext("2d");

        this._brfCanvas = document.createElement("canvas");
        this._brfCanvasContext = this._brfCanvas.getContext("2d");

        this._brfManager = new BRF_V4.BRFManager();

        // this._mediaCanvas.style.position = "absolute";
        // this._mediaCanvas.style.top = "0";
        // this._mediaCanvas.style.left = "0";
        // this._mediaCanvas.style.opacity = "0.5";
        // document.body.appendChild(this._mediaCanvas);

        // document.body.appendChild(this._snapshotCanvas);
        // this._snapshotCanvas.style.width = "25vw";
        // this._snapshotCanvas.style.height = "25vw";
        // this._snapshotCanvas.style.position = "absolute";
        // this._snapshotCanvas.style.left = "0";
        // this._snapshotCanvas.style.top = "0";

        /**
        * Dispatched when element has finished loading
        * @event ScottishWidowsFaceAgingElement#load
        */

        /**
        * Dispatched when there is an error during initialization
        * @type {ErrorEvent}
        * @event ScottishWidowsFaceAgingElement#error
        */

        this._scene = new Scene({
          renderer: this._renderer,
          cameraVideo: this._mediaCanvas,
          displayDebugMesh: this.hasAttribute("debugmesh") && this.getAttribute("debugmesh") !== "false",
        });
        this._scene.mirror = this.mirror;
        this._scene.face.displayGreyHair = !this.hasAttribute("nogreyhair");
        this.age = this.age;
        
        const brfSDKInterval = setInterval(() => {
          if (BRF_V4.sdkReady) {
            clearInterval(brfSDKInterval);
            this.dispatchEvent(new Event("load"));
          }
        }, 500);
      }

      window.addEventListener("resize", this._resizeBinded);
      this.resize();
      this.play();
    }).catch((error) => {
      this.dispatchEvent(new ErrorEvent("error", {
        error,
      }));
    });
  }

  disconnectedCallback() {
    clearTimeout(this._timeoutId);
    this._faceDetected = false;
    window.removeEventListener("resize", this._resizeBinded);
  }

  /**
   * The current media to use as input
   * @type HTMLCanvasElement | HTMLImageElement | HTMLVideoElement
   */
  get input() {
    return this._media;
  }

  set input(value) {
    this._media = value;
  }

  /**
   * @description Tells whether the experience is paused
   * @readonly
   * @type {boolean}
   */
  get paused() {
    return this._paused;
  }

  /**
   * Play experience
   */
  play() {
    this._paused = false;
    this._update();
  }

  /**
   * @description Mirror canvas (e.g. for front camera use)
   * @type {boolean}
   */
  get mirror() {
    return this.hasAttribute("mirror");
  }

  set mirror(value) {
    if (value) {
      this.setAttribute("mirror", "");
    } else {
      this.removeAttribute("mirror");
    }
  }

  /**
   * Pause experience
   */
  pause() {
    clearTimeout(this._timeoutId);
    this._paused = true;
  }

  /**
   * @type {number}
   * @description Age between 50 and 70
   */
  get age() {
    return this._age;
  }

  set age(value) {
    this._age = value;
    if (this._scene) {
      this._scene.age = this._age;
    }
  }

  /**
   * Make a snapshot of the actual canvas
   * @returns {string} The snapshot result as data URI
   */
  snapshot() {
    const ratio = this._canvas.height / this._canvas.width;

    const width = (ratio < 1) ? 1080 : 1080 / ratio;
    const height = (ratio < 1) ? 1080 * ratio : 1080;

    this._snapshotCanvas.width = width;
    this._snapshotCanvas.height = height;

    this._snapshotCanvasContext.drawImage(
      this._canvas,
      0,
      0,
      width,
      height,
    );

    return this._snapshotCanvas.toDataURL("image/jpeg");
  }

  /**
   * Force resize (e.g. if element size change via CSS)
   */
  resize() {
    const width = this._canvas.offsetWidth;
    const height = this._canvas.offsetHeight;
    this._scene.resize(width, height);
    this._renderer.setSize(width, height, false);
    this._renderer.render(this._scene, this._scene.camera);
  }

  _checkMediaSize() {
    let mediaWidth = this._media.videoWidth || this._media.width;
    let mediaHeight = this._media.videoHeight || this._media.height;

    if (!mediaWidth || !mediaHeight) {
      return;
    }

    if (Math.abs(this._cameraRotation) === 90) {
      [mediaWidth, mediaHeight] = [mediaHeight, mediaWidth];
    }

    if (this._mediaWidth === mediaWidth && this._mediaHeight === mediaHeight) {
      return;
    }

    this._mediaWidth = mediaWidth;
    this._mediaHeight = mediaHeight;

    this._mediaCanvas.width = this._mediaWidth;
    this._mediaCanvas.height = this._mediaHeight;

    const scaleRatio = Math.min(COMPUTED_MEDIA_WIDTH / this._mediaWidth, COMPUTED_MEDIA_HEIGHT / this._mediaHeight);

    this._computedMediaWidth = this._mediaWidth * scaleRatio;
    this._computedMediaHeight = this._mediaHeight * scaleRatio;

    this._brfCanvas.width = this._computedMediaWidth;
    this._brfCanvas.height = this._computedMediaHeight;

    const resolution = new BRF_V4.Rectangle(0, 0, this._computedMediaWidth, this._computedMediaHeight);
    this._brfManager.init(resolution, resolution, "com.damienmortini.scottishwidows.faceaging");

    this.resize();
  }

  /**
  * Dispatched when a face enter the view
  *
  * @event ScottishWidowsFaceAgingElement#faceenter
  */

  /**
  * Dispatched when a face leave the view
  *
  * @event ScottishWidowsFaceAgingElement#faceleave
  */

  get faceDetected() {
    return this.__faceDetected;
  }

  set _faceDetected(value) {
    if (value === this.__faceDetected) {
      return;
    }
    this.__faceDetected = value;
    this._scene.faceVisible = this.__faceDetected;
    this.dispatchEvent(new Event(this.__faceDetected ? "faceenter" : "faceleave"));
  }

  _update() {
    const startTime = window.performance.now();

    clearTimeout(this._timeoutId);

    if (this._media) {
      this._checkMediaSize();
    }

    if (!BRF_V4.sdkReady || !this._media || !this._mediaWidth || !this._mediaHeight) {
      this._timeoutId = setTimeout(this._updateBinded, 1000 / 30);
      return;
    }

    this._mediaCanvasContext.setTransform(1, 0, 0, 1, 0, 0);
    if (Math.abs(this._cameraRotation) === 90) {
      this._mediaCanvasContext.translate(this._mediaWidth * .5, this._mediaHeight * .5);
      this._mediaCanvasContext.rotate(this._cameraRotation / 180 * Math.PI);
      this._mediaCanvasContext.translate(-this._mediaHeight * .5, -this._mediaWidth * .5);
      this._mediaCanvasContext.drawImage(this._media, 0, 0, this._mediaHeight, this._mediaWidth);
    } else {
      this._mediaCanvasContext.drawImage(this._media, 0, 0, this._mediaWidth, this._mediaHeight);
    }

    this._brfCanvasContext.drawImage(this._mediaCanvas, 0, 0, this._computedMediaWidth, this._computedMediaHeight);

    this._brfManager.update(this._brfCanvasContext.getImageData(0, 0, this._computedMediaWidth, this._computedMediaHeight).data);

    this._face = this._brfManager.getFaces()[0];

    this._faceDetected = this._face.state === "state_face_tracking";

    this._scene.update({
      faceData: this._face,
      imageDataWidth: this._computedMediaWidth,
      imageDataHeight: this._computedMediaHeight,
    });

    this._renderer.render(this._scene, this._scene.camera);

    this._timeoutId = setTimeout(this._updateBinded, 1000 / 30 - (window.performance.now() - startTime));
  }
}

window.customElements.define("scottishwidows-faceaging", ScottishWidowsFaceAgingElement);
