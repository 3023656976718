import { Scene as THREEScene } from "../../node_modules/three/src/scenes/Scene.js";
import THREETrackballController from "../../node_modules/@damienmortini/three/controller/THREETrackballController.js";
import Face from "./Face.js";
import FaceUnwrapper from "./FaceUnwrapper.js";
import Environment from "./environment/Environment.js";
import { Mesh } from "../../node_modules/three/src/objects/Mesh.js";
import { PlaneBufferGeometry } from "../../node_modules/three/src/geometries/PlaneGeometry.js";
import { OrthographicCamera } from "../../node_modules/three/src/cameras/OrthographicCamera.js";
import { VideoTexture, LinearFilter, RGBFormat, MirroredRepeatWrapping, SphereGeometry, IcosahedronGeometry, WebGLRenderTarget, Texture } from "../../node_modules/three/src/Three.js";
import THREEShaderMaterial from "../../node_modules/@damienmortini/three/material/THREEShaderMaterial.js";
import GUI from "../../node_modules/@damienmortini/graph/javascript/GUI.js";
import { TweenLite } from "../../node_modules/gsap/TweenLite.js";

const SCALE = 1;

export default class Scene extends THREEScene {
  static load() {
    return Promise.all([
      Face.load(),
      Environment.load(),
    ]);
  }

  constructor({
    renderer,
    cameraVideo,
    displayDebugMesh,
  }) {
    super();

    this.renderer = renderer;

    this._aspectRatio = 1;

    this.camera = new OrthographicCamera(-1, 1, 1, -1, .01, 100);

    this._renderTargetCamera = this.camera.clone();
    this._renderTargetCamera.position.z = 10;

    this.controls = new THREETrackballController(this.camera, {
      distance: 10,
      domElement: renderer.domElement,
    });
    this.controls.enabled = false;

    this._faceRenderTarget = new WebGLRenderTarget(1, 1);
    this._faceRenderTarget.texture.premultiplyAlpha = true;

    this._cameraVideoTexture = new Texture(cameraVideo);
    this._cameraVideoTexture.minFilter = LinearFilter;
    this._cameraVideoTexture.magFilter = LinearFilter;
    this._cameraVideoTexture.format = RGBFormat;

    this._quad = new Mesh(new PlaneBufferGeometry(2, 2), new THREEShaderMaterial({
      uniforms: {
        faceComputedTexture: this._faceRenderTarget.texture,
        cameraVideoTexture: this._cameraVideoTexture,
        aspectRatio: 1,
      },
      vertexShaderChunks: [
        ["start", `
          uniform float aspectRatio;
          uniform float videoAspectRatio;
          uniform float videoScale;
          uniform float mirror;

          varying vec2 vUv;
          varying vec2 vVideoUv;
        `],
        ["end", `
          vec2 uv = uv;
          vec2 videoUv = uv;

          videoUv = videoUv * 2. - 1.;
          videoUv.x *= aspectRatio / videoAspectRatio;
          videoUv /= videoScale;
          videoUv = videoUv * .5 + .5;

          videoUv.x = mix(videoUv.x, 1. - videoUv.x, mirror);

          uv.x = mix(1. - uv.x, uv.x, mirror);

          vUv = uv;
          vVideoUv = videoUv;
        `]
      ],
      fragmentShaderChunks: [
        ["start", `
          uniform sampler2D cameraVideoTexture;
          uniform sampler2D faceComputedTexture;

          varying vec2 vUv;
          varying vec2 vVideoUv;
        `],
        ["end", `
          vec4 faceTexel = texture2D(faceComputedTexture, vUv);
          vec4 videoTexel = texture2D(cameraVideoTexture, vVideoUv);
          
          vec3 color = mix(videoTexel.rgb, faceTexel.rgb, faceTexel.a);

          gl_FragColor = vec4(color, 1.);
        `]
      ]
    }));
    this.add(this._quad);

    this._faceScene = new THREEScene();

    this._faceUnwrapper = new FaceUnwrapper({
      renderer,
      cameraVideoTexture: this._cameraVideoTexture,
    });

    this.face = new Face({
      renderer,
      videoTexture: this._cameraVideoTexture,
      faceTexture: this._faceUnwrapper.texture,
      displayDebugMesh,
    });
    this._faceScene.add(this.face);

    this.environment = new Environment({
      renderer,
      cameraVideoTexture: this._cameraVideoTexture,
      skinColorTexture: this.face.skinColorTexture,
      faceGeometry: this.face.model.geometry,
      faceTexture: this._faceUnwrapper.texture
    });

    this.face.lightMap = this.environment.texture;

    const sphere = new Mesh(new IcosahedronGeometry(.2, 3), new THREEShaderMaterial({
      uniforms: {
        lightMap: this.environment.texture,
      },
      vertexShaderChunks: [
        ["start", `
          varying vec2 vUv;
        `],
        ["end", `
          vUv = normal.xy * .5 + .5;
        `],
      ],
      fragmentShaderChunks: [
        ["start", `
          uniform sampler2D lightMap;

          varying vec2 vUv;
        `],
        ["end", `
          gl_FragColor = texture2D(lightMap, vUv);
        `],
      ],
    }));
    sphere.position.set(.75, .75, 0);
    sphere.visible = false;
    this.add(sphere);

    GUI.add({
      label: "Display Lighmap Sphere",
      object: sphere,
      key: "visible",
      folder: "💡 Lighting"
    });
  }

  get mirror() {
    return this._quad.material.mirror === 1;
  }
  
  set mirror(value) {
    this._quad.material.mirror = value ? 1 : 0;
  }

  get age() {
    return this.face.age;
  }

  set age(value) {
    this.face.age = value;
  }

  resize(width, height) {
    this._aspectRatio = width / height;

    this._renderTargetCamera.left = -1 * this._aspectRatio;
    this._renderTargetCamera.right = 1 * this._aspectRatio;
    this._renderTargetCamera.top = 1;
    this._renderTargetCamera.bottom = -1;
    this._renderTargetCamera.updateProjectionMatrix();

    this.camera.left = -1 * this._aspectRatio / SCALE;
    this.camera.right = 1 * this._aspectRatio / SCALE;
    this.camera.top = 1 / SCALE;
    this.camera.bottom = -1 / SCALE;

    this._quad.scale.x = width / height;
    this._quad.material.aspectRatio = this._aspectRatio;
    this.camera.updateProjectionMatrix();

    this._faceRenderTarget.setSize(width, height);
  }

  get faceVisible() {
    return this._faceVisible;
  }

  set faceVisible(value) {
    if (this._faceVisible === value) {
      return;
    }
    this._faceVisible = value;
    TweenLite.to(this.face, .2, {
      opacity: this._faceVisible ? 1 : 0,
    });
  }

  update({
    faceData,
    imageDataWidth = 640,
    imageDataHeight = 480,
  }) {
    this._cameraVideoTexture.needsUpdate = true;

    this.controls.update();

    let scale = 1;
    const videoAspectRatio = imageDataWidth / imageDataHeight;
    if (this._aspectRatio > videoAspectRatio) {
      scale = this._aspectRatio / videoAspectRatio;
    }

    this._quad.material.videoAspectRatio = videoAspectRatio;
    this._quad.material.videoScale = scale;

    this._faceUnwrapper.update({
      faceData,
      imageDataWidth,
      imageDataHeight,
    });

    this.environment.update();

    this.face.update({
      data: faceData,
      scale,
      imageDataWidth,
      imageDataHeight,
      aspectRatio: this._aspectRatio,
    });

    this.renderer.setRenderTarget(this._faceRenderTarget);
    this.renderer.render(this._faceScene, this._renderTargetCamera);
    this.renderer.setRenderTarget(null);
  }
}
