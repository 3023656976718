import GUI from "../../node_modules/@damienmortini/graph/javascript/GUI.js";

import THREEShaderMaterial from "../../node_modules/@damienmortini/three/material/THREEShaderMaterial.js";
import THREELoader from "../../node_modules/@damienmortini/three/loader/THREELoader.js";
import { Object3D } from "../../node_modules/three/src/core/Object3D.js";
import { Mesh } from "../../node_modules/three/src/objects/Mesh.js";
import { BufferGeometry } from "../../node_modules/three/src/core/BufferGeometry.js";
import { BufferAttribute } from "../../node_modules/three/src/core/BufferAttribute.js";
import { DoubleSide, NearestFilter } from "../../node_modules/three/src/constants.js";
import { MeshBasicMaterial } from "../../node_modules/three/src/materials/MeshBasicMaterial.js";

import BeyondRealityFaceMeshData from "./BeyondRealityFaceMeshData.js";
import { Vector2 } from "../../node_modules/three/src/math/Vector2.js";
import { Vector3 } from "../../node_modules/three/src/math/Vector3.js";
import { BoxGeometry } from "../../node_modules/three/src/geometries/BoxGeometry.js";
import { MeshNormalMaterial } from "../../node_modules/three/src/materials/MeshNormalMaterial.js";
import { Matrix4 } from "../../node_modules/three/src/math/Matrix4.js";
import { Euler } from "../../node_modules/three/src/math/Euler.js";
import NormalShader from "../../node_modules/@damienmortini/lib/shader/NormalShader.js";
import GradientNoiseShader from "../../node_modules/@damienmortini/lib/shader/noise/GradientNoiseShader.js";
import THREEWebGLRenderTarget2D from "../../node_modules/@damienmortini/three/renderer/THREEWebGLRenderTarget2D.js";
import ColorShader from "../../node_modules/@damienmortini/lib/shader/ColorShader.js";
import LUTShader from "../../node_modules/@damienmortini/lib/shader/LUTShader.js";
import { Texture } from "../../node_modules/three/src/Three.js";

const MESH_SCALE = 4.4338 * 4;

let FACE_AGE_DATA = new Map();

let MODEL;
let ARMATURE;

const ROTATION_MATRIX = new Matrix4();
const ROTATION_MATRIX_INVERSE = new Matrix4();
const FACE_ROTATION = new Euler();
const POSITION = new Vector3();

const TEXTURES = new Map();

const DISPLAY_BASE_CUBE = GUI.add({
  label: "Base Cube",
  value: false,
  id: "face/displayBaseCube",
  folder: "Advanced/Helpers",
  reload: true,
}).value;

const DISPLAY_BONES = GUI.add({
  label: "Bones",
  value: false,
  id: "face/displayBones",
  folder: "Advanced/Helpers",
  reload: true,
}).value;

const ANIMATED_FACE = GUI.add({
  label: "Animate face",
  value: true,
  id: "face/animatedface",
  folder: "Advanced/Face",
  reload: true,
}).value;

export default class Face extends Object3D {
  static load() {
    return THREELoader.load([
      "src/main/model/face/face.glb",
      "face-age-data.json",
      "src/main/model/face/textures/UV_distortion_checker.jpg",
      {
        src: "src/main/model/face/textures/SW_OpacityMap.jpg",
        type: "image/jpg",
      },
      {
        src: "src/main/model/face/textures/SW_Young_AmbOcc.jpg",
        type: "image/jpg",
      },
      {
        src: "src/main/model/face/textures/SW_Old_AmbOcc.jpg",
        type: "image/jpg",
      },
      {
        src: "src/main/model/face/textures/SW_Young_Normal.jpg",
        type: "image/jpg",
      },
      {
        src: "src/main/model/face/textures/SW_Old_Normal.jpg",
        type: "image/jpg",
      },
      {
        src: "src/main/model/face/textures/SW_Young_BaseColor.jpg",
        type: "image/jpg",
      },
      {
        src: "src/main/model/face/textures/SW_Old_BaseColor.jpg",
        type: "image/jpg",
      },
      {
        src: "src/main/model/face/textures/SW_Grey_LIGHT.png",
        type: "image/jpg",
      },
      {
        src: "src/main/model/face/textures/SW_Grey_MID.png",
        type: "image/jpg",
      },
      {
        src: "src/main/model/face/textures/SW_Grey_DARK.png",
        type: "image/jpg",
      },
    ]).then(([
      data,
      faceAgeData,
      uvDistortionCheckerTexture,
      opacityImage,
      aoOldImage,
      aoOlderImage,
      normalOldImage,
      normalOlderImage,
      baseColorOldImage,
      baseColorOlderImage,
      hairLUTLightImage,
      hairLUTMiddleImage,
      hairLUTDarkImage,
    ]) => {
      for (const key in faceAgeData) {
        FACE_AGE_DATA.set(Number(key), faceAgeData[key]);
      }

      MODEL = MODEL || data.scene.getObjectByName("High_Base_Mesh");
      ARMATURE = ARMATURE || data.scene.getObjectByName("Armature");

      TEXTURES.set("uvDistortionChecker", uvDistortionCheckerTexture);

      const hairLUTCanvas = document.createElement("canvas");
      hairLUTCanvas.width = hairLUTLightImage.width * 2;
      hairLUTCanvas.height = hairLUTLightImage.height * 2;
      const hairLUTCanvasContext = hairLUTCanvas.getContext("2d");
      hairLUTCanvasContext.drawImage(hairLUTLightImage, 0, 0);
      hairLUTCanvasContext.drawImage(hairLUTMiddleImage, hairLUTLightImage.width, 0);
      hairLUTCanvasContext.drawImage(hairLUTDarkImage, 0, hairLUTLightImage.height);

      // hairLUTCanvas.style.width = "15vw";
      // hairLUTCanvas.style.height = "15vw";
      // hairLUTCanvas.style.position = "relative";
      // document.body.appendChild(hairLUTCanvas);

      const hairLUTTexture = new Texture(hairLUTCanvas);
      hairLUTTexture.generateMipmaps = false;
      hairLUTTexture.minFilter = NearestFilter;
      hairLUTTexture.magFilter = NearestFilter;
      hairLUTTexture.needsUpdate = true;
      TEXTURES.set(name, hairLUTTexture);
      TEXTURES.set("hairLUT", hairLUTTexture);

      const opacityCanvas = document.createElement("canvas");
      opacityCanvas.width = opacityImage.width;
      opacityCanvas.height = opacityImage.height;
      const opacityCanvasContext = opacityCanvas.getContext("2d");
      opacityCanvasContext.drawImage(opacityImage, 0, 0);
      const imageData = opacityCanvasContext.getImageData(0, 0, opacityCanvas.width, opacityCanvas.height);
      const pixelsData = imageData.data;
      const length = pixelsData.length / 4;
      for (let index = 0; index < length; index++) {
        pixelsData[index * 4 + 3] = 255 - pixelsData[index * 4];
        pixelsData[index * 4] = 255;
        pixelsData[index * 4 + 1] = 255;
        pixelsData[index * 4 + 2] = 255;
      }
      opacityCanvasContext.putImageData(imageData, 0, 0);

      // opacityCanvas.style.width = "15vw";
      // opacityCanvas.style.height = "15vw";
      // opacityCanvas.style.position = "relative";
      // document.body.appendChild(opacityCanvas);

      for (const [name, oldImage, olderImage] of [
        ["ao", aoOldImage, aoOlderImage],
        ["normal", normalOldImage, normalOlderImage],
        ["baseColor", baseColorOldImage, baseColorOlderImage],
      ]) {
        const canvas = document.createElement("canvas");
        canvas.width = oldImage.width * 2;
        canvas.height = oldImage.height;
        const context = canvas.getContext("2d");
        context.globalCompositeOperation = "source-over";
        context.drawImage(oldImage, 0, 0);
        context.drawImage(olderImage, oldImage.width, 0);
        context.globalCompositeOperation = "destination-out";
        context.drawImage(opacityCanvas, 0, 0);

        // canvas.style.width = "30vw";
        // canvas.style.height = "15vw";
        // canvas.style.position = "relative";
        // document.body.appendChild(canvas);

        const texture = new Texture(canvas);
        texture.needsUpdate = true;
        TEXTURES.set(name, texture);
      }

      for (const texture of TEXTURES.values()) {
        texture.flipY = false;
      }
    });
  }

  constructor({
    renderer,
    videoTexture,
    faceTexture,
    displayDebugMesh = false,
  }) {
    super();

    this._skinColorRenderTarget2D = new THREEWebGLRenderTarget2D({
      renderer,
      width: 1,
      height: 1,
      material: new THREEShaderMaterial({
        uniforms: {
          faceMap: faceTexture,
        },
        vertexShaderChunks: [
          ["start", `
            varying vec2 vUv;
          `],
          ["main", `
            vec3 position = position;
            position.y -= .3;
            position *= 6.;
    
            vUv = uv;
          `],
        ],
        fragmentShaderChunks: [
          ["start", `
            uniform sampler2D faceMap;
            varying vec2 vUv;
          `],
          ["end", `
            vec4 faceTexel = texture2D(faceMap, vUv);
            vec3 color = faceTexel.rgb;
  
            gl_FragColor = vec4(color, 1.);
          `],
        ]
      })
    });

    // BRF Mesh

    const brfGeometry = new BufferGeometry();

    this._brfPositionsBufferAttribute = new BufferAttribute(new Float32Array(BeyondRealityFaceMeshData.positions), 2);
    this._brfPositionsBufferAttribute.setDynamic(true);
    brfGeometry.addAttribute("position", this._brfPositionsBufferAttribute);
    brfGeometry.setIndex(new BufferAttribute(new Uint32Array(BeyondRealityFaceMeshData.indices), 1));

    const brfMesh = new Mesh(brfGeometry, new MeshBasicMaterial({
      side: DoubleSide,
      depthTest: false,
      transparent: true,
      opacity: .5,
      wireframe: true,
      color: 0xff0000,
    }));
    brfMesh.frustumCulled = false;
    brfMesh.visible = displayDebugMesh;
    this.add(brfMesh);

    GUI.add({
      label: "Mesh",
      object: brfMesh,
      key: "visible",
      id: "brfDebugMode",
      folder: "Advanced/BRF",
    });

    // BRF Static Mesh

    const brfStaticGeometry = new BufferGeometry();

    this._brfStaticPositionsBufferAttribute = new BufferAttribute(new Float32Array(BeyondRealityFaceMeshData.positions), 2);
    this._brfStaticPositionsBufferAttribute.setDynamic(true);
    brfStaticGeometry.addAttribute("position", this._brfStaticPositionsBufferAttribute);
    brfStaticGeometry.setIndex(new BufferAttribute(new Uint32Array(BeyondRealityFaceMeshData.indices), 1));

    const brfStaticMesh = new Mesh(brfStaticGeometry, new MeshBasicMaterial({
      side: DoubleSide,
      depthTest: false,
      transparent: true,
      opacity: .5,
      wireframe: true,
      color: 0xff0000,
    }));
    brfStaticMesh.frustumCulled = false;
    brfStaticMesh.visible = false;
    this.add(brfStaticMesh);

    GUI.add({
      label: "Static Mesh",
      object: brfStaticMesh,
      key: "visible",
      id: "brfStaticDebugMode",
      folder: "Advanced/BRF",
    });

    this._modelMaterial = new THREEShaderMaterial({
      type: "basic",
      skinning: true,
      morphTargets: true,
      morphNormals: true,
      transparent: true,
      premultipliedAlpha: true,
      uniforms: {
        videoTexture,
        baseColorTexture: TEXTURES.get("baseColor"),
        normalTexture: TEXTURES.get("normal"),
        aoTexture: TEXTURES.get("ao"),
        uvDistortionCheckerTexture: TEXTURES.get("uvDistortionChecker"),
        skinColorTexture: this.skinColorTexture,
        hairLUTTexture: TEXTURES.get("hairLUT"),
        opacity: 1,
        aspectRatio: 1,
        videoAspectRatio: 1,
        videoScale: 1,
        uvDistortionCheck: false,
        showSkinExtraction: false,
        showHairExtraction: false,
        ageRatio: 0,
        baseColorOldRatio: .1,
        baseColorOlderRatio: .1,
        normalOldRatio: 1,
        normalOlderRatio: 1,
        aoOldRatio: .1,
        aoOlderRatio: .1,
        faceTextureRatio: 1,
        greyHairRatio: 1,
        displayGreyHair: true,
      },
      vertexShaderChunks: [
        ["start", `
          uniform mat4 normalMatrix2;
          uniform float aspectRatio;
          uniform float videoAspectRatio;
          uniform float videoScale;

          attribute vec2 uv2;
          attribute vec4 color;

          varying vec3 vNormal;
          varying vec2 vUv;
          varying vec2 vVideoUv;
          varying vec2 vFaceUv;
          varying vec4 vColor;
        `],
        ["#include <morphtarget_vertex>", `
          #include <morphtarget_vertex>
          
          vec3 morphTransformed = transformed;
        `],
        ["end", `
          vec3 normal = normal;
          normal += (morphNormal0 - normal) * morphTargetInfluences[0];
          normal.xyz = normal.xzy;
          normal.y *= -1.;
          normal = (normalMatrix2 * vec4(normal, 1.)).xyz;

          vec2 faceUv = uv2;
          faceUv.y = 1. - faceUv.y;

          vec4 skinVertex2 = bindMatrix * vec4(position, 1.);
          vec4 skinned2 = vec4( 0.0 );
          skinned2 += boneMatX * skinVertex2 * skinWeight.x;
          skinned2 += boneMatY * skinVertex2 * skinWeight.y;
          skinned2 += boneMatZ * skinVertex2 * skinWeight.z;
          skinned2 += boneMatW * skinVertex2 * skinWeight.w;

	        vec3 onlySkinnedPosition = (bindMatrixInverse * skinned2).xyz;
          vec4 intermediatePosition = modelViewMatrix * vec4(onlySkinnedPosition, 1.);
          intermediatePosition = projectionMatrix * intermediatePosition;

          vec2 videoUv = (intermediatePosition.xy / intermediatePosition.w) * .5 + .5;
          videoUv = videoUv * 2. - 1.;
          videoUv.x *= aspectRatio / videoAspectRatio;
          videoUv /= videoScale;
          videoUv = videoUv * .5 + .5;
          videoUv.x = 1. - videoUv.x;

          vNormal = normal;
          vUv = uv;
          vVideoUv = videoUv;
          vFaceUv = faceUv;
          vColor = color;
        `]
      ],
      fragmentShaderChunks: [
        ["start", `
          uniform sampler2D baseColorTexture;
          uniform sampler2D normalTexture;
          uniform sampler2D aoTexture;
          // uniform sampler2D uvDistortionCheckerTexture;
          uniform sampler2D lightSphericalTexture;
          uniform sampler2D videoTexture;
          uniform sampler2D skinColorTexture;
          uniform sampler2D hairLUTTexture;
          uniform bool uvDistortionCheck;
          uniform bool showSkinExtraction;
          uniform bool showHairExtraction;
          uniform float ageRatio;
          uniform float baseColorOldRatio;
          uniform float baseColorOlderRatio;
          uniform float aoOldRatio;
          uniform float aoOlderRatio;
          uniform float normalOldRatio;
          uniform float normalAccentuationOldRatio;
          uniform float normalOlderRatio;
          uniform float normalAccentuationOlderRatio;
          uniform float faceTextureRatio;
          uniform float greyHairRatio;
          uniform bool displayGreyHair;

          varying vec2 vUv;
          varying vec2 vVideoUv;
          varying vec2 vFaceUv;
          varying vec4 vColor;

          ${NormalShader.blendNormals()}
          ${GradientNoiseShader.gradientNoise2D()}
          ${ColorShader.rgbToHSV()}
          ${LUTShader.computeLUT()}

          float blendOverlay(float base, float blend) {
            return base<0.5?(2.0*base*blend):(1.0-2.0*(1.0-base)*(1.0-blend));
          }
          
          vec3 blendOverlay(vec3 base, vec3 blend) {
            return vec3(blendOverlay(base.r,blend.r),blendOverlay(base.g,blend.g),blendOverlay(base.b,blend.b));
          }

          float random(float n){
            return fract(sin(n) * 43758.5453123);
          }
        `],
        ["end", `
          vec3 normal = vNormal;
          
          vec2 leftUv = vec2(vUv.x * .5, vUv.y);
          vec2 rightUv = vec2(.5 + vUv.x * .5, vUv.y);

          vec4 faceColor = texture2D(videoTexture, vVideoUv);

          vec3 skinColor = texture2D(skinColorTexture, vec2(0.)).rgb;

          vec3 color = mix(vec3(.5), faceColor.rgb, faceTextureRatio);

          vec4 baseColorOldTexel = texture2D(baseColorTexture, leftUv);
          vec4 baseColorOlderTexel = texture2D(baseColorTexture, rightUv);

          float faceOpacity = baseColorOldTexel.a;

          float skinColorDifference = smoothstep(.25, .75, 1. - min(1., length(skinColor - color)));
          float hairColorDifference = smoothstep(0., 1., min(1., length(skinColor - color)));

          vec3 skinHSV = rgbToHSV(skinColor);
          vec3 colorHSV = rgbToHSV(color);

          float opacity = faceColor.a * opacity;

          // Grey hair

          if(displayGreyHair) {
            float skinHueAngle = skinHSV.r * PI * 2.;
            float colorHueAngle = colorHSV.r * PI * 2.;
  
            float greyHair = gradientNoise2D(vFaceUv * vec2(1., .1) * 300.) * .5 + .5;
            greyHair = greyHair * .5 + (gradientNoise2D(colorHSV.rg) * .5 + .5) * .5;
            greyHair *= gradientNoise2D(vFaceUv * 5.) * .75 + .25;
  
            float hueDifference = dot(vec2(cos(skinHueAngle), sin(skinHueAngle)), vec2(cos(colorHueAngle), sin(colorHueAngle)));
            hueDifference = (hueDifference * .5 + .5);
  
            hairColorDifference *= min(1., smoothstep(.25, .35, abs(vUv.y * 2. - 1.)) + smoothstep(.4, 1., abs(vUv.x * 2. - 1.)));
            hairColorDifference *= min(1., faceOpacity + smoothstep(.2, .3, abs((vUv.y - .25) * 2. - 1.)) + smoothstep(.3, .4, abs(vUv.x * 2. - 1.)));
            // hairColorDifference *= 1. - hueDifference;
            // hairColorDifference = clamp(hairColorDifference, 0., 1.);
            hairColorDifference *= vColor.r;
  
            // Mix grey hair
            float lightHairRatio = max(0., colorHSV.z * 2. - 1.);
            float darkHairRatio = max(0., -(colorHSV.z * 2. - 1.));
            vec3 hairColor =  computeLUT(color, hairLUTTexture, vec4(.5, 0., .5, .5));
            hairColor = mix(hairColor, computeLUT(color, hairLUTTexture, vec4(0., 0., .5, .5)), lightHairRatio);
            hairColor = mix(hairColor, computeLUT(color, hairLUTTexture, vec4(0., .5, .5, .5)), darkHairRatio * .8);
  
            float greyHairRatio = hairColorDifference * greyHairRatio;
            color = mix(color, hairColor, greyHairRatio);
          }

          // Base Color

          color = blendOverlay(color, mix(vec3(.5), baseColorOldTexel.rgb, baseColorOldRatio * skinColorDifference * faceOpacity));
          color = blendOverlay(color, mix(vec3(.5), baseColorOlderTexel.rgb, baseColorOlderRatio * skinColorDifference * faceOpacity));

          // Normal

          vec3 normalOld = texture2D(normalTexture, leftUv).rgb * 2. - 1.;
          vec3 normalOlder = texture2D(normalTexture, rightUv).rgb * 2. - 1.;

          normalOld.y *= -1.;
          normalOld.xy *= 1. + normalAccentuationOldRatio;
          normalOld = normalize(normalOld);
          
          normalOlder.y *= -1.;
          normalOlder.xy *= 1. + normalAccentuationOlderRatio;
          normalOlder = normalize(normalOlder);
          
          normal = mix(normal, blendNormals(normal, normalOld), normalOldRatio * skinColorDifference * faceOpacity);
          normal = mix(normal, blendNormals(normal, normalOlder), normalOlderRatio * skinColorDifference * faceOpacity);

          vec3 envLight = texture2D(lightSphericalTexture, normal.xy * .5 + .5).rgb;

          vec3 lightColor = envLight;

          color *= 1. + (lightColor * 2. - 1.) * .5 * skinColorDifference * faceOpacity;

          // Ambient Occlusion
          color = mix(color, color * texture2D(aoTexture, leftUv).rgb, aoOldRatio * skinColorDifference * faceOpacity);
          color = mix(color, color * texture2D(aoTexture, rightUv).rgb, aoOlderRatio * skinColorDifference * faceOpacity);

          gl_FragColor = vec4(color, opacity);
          
          // if (uvDistortionCheck) {
          //   gl_FragColor = texture2D(uvDistortionCheckerTexture, vUv);
          //   gl_FragColor.a = opacity;
          // }

          if (showSkinExtraction) {
            gl_FragColor.rgb = mix(gl_FragColor.rgb, vec3(1., 0., 0.), skinColorDifference);
          }

          if (showHairExtraction) {
            gl_FragColor.rgb = mix(gl_FragColor.rgb, vec3(1., 1., 0.), greyHairRatio);
          }

          // gl_FragColor = vColor;
          // gl_FragColor = vec4(vec3(hairColorDifference), opacity);
          // gl_FragColor.rgb = vec3(hueDifference);
          // gl_FragColor.rgb = vec3(smoothstep(.2, .3, abs(vUv.y * 2. - 1.)));
          // gl_FragColor.rgb = vec3(faceOpacity);
          // gl_FragColor.rgb = vec3(greyHair * hairColorDifference);
          // gl_FragColor.rgb = vec3(colorHSV.b);
          // gl_FragColor.a = 1.;
        `],
      ],
    });

    this._updateModel();

    this.age = [...FACE_AGE_DATA.keys()][0];

    // GUI

    // GUI.add({ 
    //   label: "Age Ratio",
    //   object: this,
    //   key: "age",
    //   folder: "👴 Face",
    // });

    GUI.add({
      label: "Base Color",
      object: this._modelMaterial,
      key: "baseColorOldRatio",
      folder: "👴 Face/Young",
    });

    GUI.add({
      label: "Normal Map",
      object: this._modelMaterial,
      key: "normalOldRatio",
      folder: "👴 Face/Young",
    });

    GUI.add({
      label: "Normal Accentuation",
      object: this._modelMaterial,
      key: "normalAccentuationOldRatio",
      max: 2,
      folder: "👴 Face/Young",
    });

    GUI.add({
      label: "AO Map",
      object: this._modelMaterial,
      key: "aoOldRatio",
      folder: "👴 Face/Young",
    });

    GUI.add({
      label: "Base Color",
      object: this._modelMaterial,
      key: "baseColorOlderRatio",
      folder: "👴 Face/Old",
    });

    GUI.add({
      label: "Normal Map",
      object: this._modelMaterial,
      key: "normalOlderRatio",
      folder: "👴 Face/Old",
    });

    GUI.add({
      label: "Normal Accentuation",
      object: this._modelMaterial,
      key: "normalAccentuationOlderRatio",
      max: 2,
      folder: "👴 Face/Old",
    });

    GUI.add({
      label: "AO Map",
      object: this._modelMaterial,
      key: "aoOlderRatio",
      folder: "👴 Face/Old",
    });

    GUI.add({
      label: "Face Extraction",
      object: this._modelMaterial,
      key: "faceTextureRatio",
      folder: "👴 Face",
    });

    GUI.add({
      label: "Morph",
      object: this.model.morphTargetInfluences,
      key: 0,
      folder: "👴 Face",
    });

    GUI.add({
      label: "Opacity",
      object: this,
      key: "opacity",
      folder: "👴 Face",
    });

    GUI.add({
      label: "Grey Hair",
      object: this._modelMaterial,
      key: "greyHairRatio",
      folder: "👴 Face",
    });

    GUI.add({
      label: "GLB File",
      tagName: "input-file",
      folder: "Advanced/Face",
      id: "glb-file",
      accept: ".glb, .gltf",
      oninput: (event) => {
        const file = event.target.files[0];
        if (!file) {
          return;
        }
        THREELoader.load({
          src: window.URL.createObjectURL(file),
          type: "gltf",
        }).then((data) => {
          this._updateModel(data);
        });
      },
    });

    if (DISPLAY_BASE_CUBE) {
      this._cube = new Mesh(new BoxGeometry(.3, .3, .3), new MeshBasicMaterial({
        wireframe: true,
        color: "#0000ff",
      }));
      this.add(this._cube);
    }
  }

  get opacity() {
    return this._modelMaterial.opacity;
  }

  set opacity(value) {
    this._modelMaterial.opacity = value;
  }

  get skinColorTexture() {
    return this._skinColorRenderTarget2D.texture;
  }

  get displayGreyHair() {
    return this._modelMaterial.displayGreyHair;
  }

  set displayGreyHair(value) {
    this._modelMaterial.displayGreyHair = value;
  }

  get age() {
    return this._age;
  }

  set age(value) {
    if (this._age === value) {
      return;
    }
    this._age = value;
    const decimal = Math.floor(this._age / 10) * 10;
    const fractRatio = (this._age - decimal) / 10;

    let from = FACE_AGE_DATA.get(decimal);
    let to = FACE_AGE_DATA.get(decimal + 10);

    if (!from) {
      from = to;
    }
    if (!to) {
      to = from;
    }

    this._modelMaterial.ageRatio = (this._age - 50) / 40;
    this._modelMaterial.baseColorOldRatio = from.baseColorOldRatio + (to.baseColorOldRatio - from.baseColorOldRatio) * fractRatio;
    this._modelMaterial.normalOldRatio = from.normalOldRatio + (to.normalOldRatio - from.normalOldRatio) * fractRatio;
    this._modelMaterial.normalAccentuationOldRatio = from.normalAccentuationOldRatio + (to.normalAccentuationOldRatio - from.normalAccentuationOldRatio) * fractRatio;
    this._modelMaterial.aoOldRatio = from.aoOldRatio + (to.aoOldRatio - from.aoOldRatio) * fractRatio;
    this._modelMaterial.baseColorOlderRatio = from.baseColorOlderRatio + (to.baseColorOlderRatio - from.baseColorOlderRatio) * fractRatio;
    this._modelMaterial.normalOlderRatio = from.normalOlderRatio + (to.normalOlderRatio - from.normalOlderRatio) * fractRatio;
    this._modelMaterial.normalAccentuationOlderRatio = from.normalAccentuationOlderRatio + (to.normalAccentuationOlderRatio - from.normalAccentuationOlderRatio) * fractRatio;
    this._modelMaterial.aoOlderRatio = from.aoOlderRatio + (to.aoOlderRatio - from.aoOlderRatio) * fractRatio;
    this._modelMaterial.greyHairRatio = from.greyHairRatio + (to.greyHairRatio - from.greyHairRatio) * fractRatio;

    this.model.morphTargetInfluences[0] = from.morph + (to.morph - from.morph) * fractRatio;
  }

  get lightMap() {
    return this._modelMaterial.lightSphericalTexture;
  }

  set lightMap(value) {
    this._modelMaterial.lightSphericalTexture = value;
  }

  _updateModel(data) {
    if (this.model) {
      this.remove(this.model);
      this.remove(this._armature);
      for (const input of this._guiInputs) {
        input.remove();
      }
    }

    if (this._debugBones) {
      for (const debugBone of this._debugBones) {
        this.remove(debugBone);
      }
    }

    this._guiInputs = new Set();

    this.model = data ? data.scene.getObjectByName("High_Base_Mesh") : MODEL;
    this.model.material = this._modelMaterial;
    this.model.morphTargetInfluences[0] = 1;
    this.age = this.age;
    this.add(this.model);

    this._guiInputs.add(GUI.add({
      label: "Opacity",
      object: this.model.material,
      key: "opacity",
      folder: "Advanced/Face",
    }));

    this._guiInputs.add(GUI.add({
      label: "Wireframe",
      object: this.model.material,
      key: "wireframe",
      folder: "Advanced/Face",
    }));

    this._guiInputs.add(GUI.add({
      label: "UV Distortion Checker",
      object: this.model.material,
      key: "uvDistortionCheck",
      folder: "Advanced/Face",
    }));

    this._guiInputs.add(GUI.add({
      label: "Skin Extraction",
      object: this.model.material,
      key: "showSkinExtraction",
      folder: "Advanced/Face",
    }));

    this._guiInputs.add(GUI.add({
      label: "Hair Extraction",
      object: this.model.material,
      key: "showHairExtraction",
      folder: "Advanced/Face",
    }));

    this._armature = data ? data.scene.getObjectByName("Armature") : ARMATURE;
    this.add(this._armature);

    this._bonesMap = new Map();
    this._bonesInitPositions = new Map();
    for (const child of this._armature.children) {
      this._bonesMap.set(child.name, child);
      this._bonesInitPositions.set(child, child.position.clone());
    }

    const vertices = [...BeyondRealityFaceMeshData.positions];
    for (let index = 0; index < BeyondRealityFaceMeshData.positions.length / 2; index++) {
      vertices[index * 2] *= .08 * MESH_SCALE;
      vertices[index * 2 + 1] *= 0.074 * MESH_SCALE;
      vertices[index * 2 + 1] -= 0.034 * MESH_SCALE;
    }

    this._brfPositionsBufferAttribute.array.set(vertices);
    this._brfPositionsBufferAttribute.needsUpdate = true;

    if (DISPLAY_BONES) {
      this._debugBones = new Map();
      for (const bone of this._bonesMap.values()) {
        const debugBone = new Mesh(new BoxGeometry(.005, .005, .005), new MeshNormalMaterial());
        this._armature.add(debugBone);
        this._debugBones.set(bone, debugBone);
      }
    }
  }

  _generateBonesVertexIDMap({ faceVerticesData, bones }) {
    const bonesMap = new Map();

    const point = new Vector2();
    const bone2DPosition = new Vector2();

    const length = faceVerticesData.length * .5;

    for (const bone of bones) {
      let closestDistance = Infinity;
      let closestPointId = -1;
      bone2DPosition.set(bone.position.x, bone.position.y);

      for (let index = 0; index < length; index++) {
        point.set(faceVerticesData[index * 2], faceVerticesData[index * 2 + 1]);
        const distance = point.distanceTo(bone2DPosition);
        if (distance < closestDistance) {
          closestPointId = index;
          closestDistance = distance;
        }
      }
      bonesMap.set(bone.name, closestPointId);
    }

    return bonesMap;
  }

  update({
    data,
    scale = 1,
    imageDataWidth = 640,
    imageDataHeight = 480,
    aspectRatio = 1,
  }) {
    this._skinColorRenderTarget2D.render();

    if (!ANIMATED_FACE) {
      return;
    }

    // Handle positions

    const videoWidth = imageDataWidth;
    const videoHeight = imageDataHeight;
    const videoAspectRatio = videoWidth / videoHeight;

    const dataScale = data.scale / videoHeight;
    const translationX = -((data.translationX / videoWidth) * 2 - 1) * videoAspectRatio * scale;
    const translationY = -(data.translationY / videoHeight * 2 - 1) * scale;

    const vertices = [...data.vertices];
    for (let index = 0; index < vertices.length * .5; index++) {
      vertices[index * 2] /= videoWidth;
      vertices[index * 2] = vertices[index * 2] * 2 - 1;
      vertices[index * 2] *= -1 * scale * videoAspectRatio;
      vertices[index * 2 + 1] /= videoHeight;
      vertices[index * 2 + 1] = vertices[index * 2 + 1] * 2 - 1;
      vertices[index * 2 + 1] *= -1 * scale;
    }

    this._brfPositionsBufferAttribute.array.set(vertices);
    this._brfPositionsBufferAttribute.needsUpdate = true;

    for (let index = 0; index < vertices.length * .5; index++) {
      vertices[index * 2] -= translationX;
      vertices[index * 2] /= dataScale;
      vertices[index * 2 + 1] -= translationY;
      vertices[index * 2 + 1] /= dataScale;
    }

    const rotationRatio = Math.max(Math.abs(data.rotationX), Math.max(Math.abs(data.rotationY), Math.abs(data.rotationZ))) / (Math.PI * .5);

    FACE_ROTATION.set(data.rotationX, data.rotationY, data.rotationZ);
    ROTATION_MATRIX.makeRotationFromEuler(FACE_ROTATION);
    ROTATION_MATRIX_INVERSE.getInverse(ROTATION_MATRIX);

    for (let index = 0; index < vertices.length * .5; index++) {
      const bone = this._bonesMap.get(`bone${index}`);
      const boneInitPosition = this._bonesInitPositions.get(bone);

      if (rotationRatio < .05) {
        boneInitPosition.x += (vertices[index * 2] / MESH_SCALE / scale - boneInitPosition.x) * .2;
        boneInitPosition.y += (vertices[index * 2 + 1] / MESH_SCALE / scale - boneInitPosition.y) * .2;
      }

      POSITION.copy(boneInitPosition);
      POSITION.applyMatrix4(ROTATION_MATRIX);
      POSITION.z *= .75;
      POSITION.x = vertices[index * 2] / MESH_SCALE / scale;
      POSITION.y = vertices[index * 2 + 1] / MESH_SCALE / scale;
      POSITION.applyMatrix4(ROTATION_MATRIX_INVERSE);

      bone.position.x = POSITION.x;
      bone.position.y = POSITION.y;
      vertices[index * 2] = POSITION.x;
      vertices[index * 2 + 1] = POSITION.y;
    }

    this._brfStaticPositionsBufferAttribute.array.set(vertices);
    this._brfStaticPositionsBufferAttribute.needsUpdate = true;

    this._armature.rotation.copy(FACE_ROTATION);

    this._armature.scale.set(MESH_SCALE, MESH_SCALE, MESH_SCALE);
    this._armature.scale.multiplyScalar(dataScale);
    this._armature.scale.multiplyScalar(scale);

    this._armature.position.x = translationX;
    this._armature.position.y = translationY;

    // Materials
    this.model.material.normalMatrix2 = ROTATION_MATRIX;
    this.model.material.aspectRatio = aspectRatio;
    this.model.material.videoAspectRatio = videoAspectRatio;
    this.model.material.videoScale = scale;

    // Debug
    if (DISPLAY_BASE_CUBE) {
      this._cube.rotation.copy(FACE_ROTATION);
      this._cube.scale.set(dataScale, dataScale, dataScale);
      this._cube.position.x = translationX;
      this._cube.position.y = translationY;
    }

    if (DISPLAY_BONES) {
      for (const bone of this._bonesMap.values()) {
        const debugBone = this._debugBones.get(bone);
        debugBone.position.copy(bone.position);
      }
    }
  }
}
