import GUI from "../node_modules/@damienmortini/graph/javascript/GUI.js";
import "./main/index.js";

// Tmp: To debug outside the build
window.ScottishwidowsFaceagingGUI = GUI;

import InputFileElement from "../node_modules/@damienmortini/elements/src/input-file/index.js";
customElements.define("input-file", InputFileElement);

import InputPad2DElement from "../node_modules/@damienmortini/elements/src/input-pad2d/index.js";
customElements.define("input-pad2d", InputPad2DElement);
