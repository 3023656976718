import { Scene } from "../../../node_modules/three/src/scenes/Scene.js";
import { OrthographicCamera } from "../../../node_modules/three/src/cameras/OrthographicCamera.js";
import { WebGLRenderTarget } from "../../../node_modules/three/src/renderers/WebGLRenderTarget.js";
import { Mesh } from "../../../node_modules/three/src/objects/Mesh.js";
import { PlaneBufferGeometry } from "../../../node_modules/three/src/geometries/PlaneGeometry.js";
import { SphereGeometry } from "../../../node_modules/three/src/geometries/SphereGeometry.js";
import THREEShaderMaterial from "../../../node_modules/@damienmortini/three/material/THREEShaderMaterial.js";
import THREETrackballController from "../../../node_modules/@damienmortini/three/controller/THREETrackballController.js";
import BlurShader from "../../../node_modules/@damienmortini/lib/shader/BlurShader.js";
import ColorShader from "../../../node_modules/@damienmortini/lib/shader/ColorShader.js";
import THREELoader from "../../../node_modules/@damienmortini/three/loader/THREELoader.js";
import GUI from "../../../node_modules/@damienmortini/graph/javascript/GUI.js";

let MAT_CAP_TEXTURE;

export default class Environment {
  static load() {
    return THREELoader.load("src/main/environment/SW_MatCap_A.jpg").then((matCapTexture) => {
      MAT_CAP_TEXTURE = matCapTexture;
    });
  }

  constructor({
    renderer,
    cameraVideoTexture,
    faceTexture,
    faceGeometry,
    skinColorTexture,
  }) {
    this._renderer = renderer;

    this._faceRenderTarget = new WebGLRenderTarget(32, 32);
    this._faceSmoothRenderTargetIn = new WebGLRenderTarget(32, 32);
    this._faceSmoothRenderTargetOut = new WebGLRenderTarget(32, 32);

    this._renderTargetIn = new WebGLRenderTarget(32, 32);
    this._renderTargetOut = new WebGLRenderTarget(32, 32);

    this._scene = new Scene();
    this._camera = new OrthographicCamera(-1, 1, 1, -1, 0, 10);
    // this._camera.position.z = 5;

    this._quad = new Mesh(new PlaneBufferGeometry(2, 2));

    this._sphere = new Mesh(new SphereGeometry(1, 20, 20));

    this._face = new Mesh(faceGeometry);
    this._face.rotation.x = Math.PI * .5;

    this._extractFaceLightMaterial = new THREEShaderMaterial({
      uniforms: {
        faceMap: faceTexture,
        skinColorTexture,
      },
      vertexShaderChunks: [
        ["start", `
          varying vec2 vUv;
        `],
        ["main", `
          vec3 position = position;
          position.y -= .3;
          position *= 6.;
  
          vUv = uv;
        `],
      ],
      fragmentShaderChunks: [
        ["start", `
          uniform sampler2D skinColorTexture;
          uniform sampler2D faceMap;
          varying vec2 vUv;

          ${ColorShader.rgbToHSL()}
        `],
        ["end", `
          vec4 skinColor = texture2D(skinColorTexture, vUv);
          vec4 faceTexel = texture2D(faceMap, vUv);
          // faceTexel.rgb = pow(faceTexel.rgb, vec3(1. / 2.2));

          vec3 hsl = rgbToHSL(faceTexel.rgb);
          vec3 skinHSL = rgbToHSL(skinColor.rgb);
          float luminance = hsl.z - skinHSL.z;
          luminance += .5;
          vec3 color = vec3(luminance);

          // vec3 color = clamp((faceTexel.rgb - vec3(0.)) / (skinColor.rgb * 2. - vec3(0.)), 0., 1.);

          gl_FragColor = vec4(color, 1.);
        `],
      ]
    });

    this._faceLightMapSmoothMaterial = new THREEShaderMaterial({
      vertexShaderChunks: [
        ["start", `
          varying vec2 vUv;
        `],
        ["main", `
          vUv = uv;
        `],
      ],
      fragmentShaderChunks: [
        ["start", `
          uniform sampler2D faceLightMap;
          uniform sampler2D previousFaceLightMap;

          varying vec2 vUv;
        `],
        ["end", `
          vec4 faceLightTexel = texture2D(faceLightMap, vUv);
          vec4 previousFaceLightTexel = texture2D(previousFaceLightMap, vUv);
          // faceLightTexel.rgb *= 1. - smoothstep(.99, 1., min(1., length(vUv * 2. - 1.)));
          // faceLightTexel.rgb = faceLightTexel.rgb * .5 + .5;
          gl_FragColor = previousFaceLightTexel + (faceLightTexel - previousFaceLightTexel) * .2;
          gl_FragColor.a = 1.;
        `],
      ]
    });

    this._cameraVideoMapMaterial = new THREEShaderMaterial({
      uniforms: {
        cameraVideoMap: cameraVideoTexture,
      },
      vertexShaderChunks: [
        ["start", `
          // uniform float cameraVideoRatio;
          
          varying vec2 vUv;
        `],
        ["main", `
          vUv = uv;
          vUv = vUv * 2. - 1.;
          vUv.x *= -1.;
          // vUv.y *= 1. / cameraVideoRatio;
          vUv = vUv * .5 + .5;
        `],
      ],
      fragmentShaderChunks: [
        ["start", `
          uniform sampler2D cameraVideoMap;

          varying vec2 vUv;
        `],
        ["end", `
          vec4 cameraVideoTexel = texture2D(cameraVideoMap, vUv);
          gl_FragColor = cameraVideoTexel;
        `],
      ]
    });

    this._envMapMaterial = new THREEShaderMaterial({
      uniforms: {
        cameraVideoMap: cameraVideoTexture,
        faceLightMap: this._renderTargetIn.texture,
      },
      vertexShaderChunks: [
        ["start", `
          #define PI_2 1.57079632679489661923

          varying vec2 vUv;
          varying vec2 vUv2;
        `],
        ["main", `
          vUv = uv;
          vUv.x = 1. - vUv.x;
          vUv.x *= 2.;
          vUv.x -= 1.;
          // vUv = vUv * 2. - 1.;
          // vUv.x = pow(abs(vUv.x), .2) * sign(vUv.x);
          // vUv.y = pow(abs(vUv.y), .2) * sign(vUv.y);
          // vUv = vUv * .5 + .5;
          
          vUv = position.xy;
          vUv.y *= 1. / .75;
          vUv.x = sin(vUv.x * PI_2);
          vUv.y = sin(vUv.y * PI_2);
          // vUv *= 2.;
          vUv.x *= -1.;
          vUv = vUv * .5 + .5;

          vUv2 = position.xy * .5 + .5;
        `],
      ],
      fragmentShaderChunks: [
        ["start", `
          uniform sampler2D cameraVideoMap;
          uniform sampler2D faceLightMap;

          varying vec2 vUv;
          varying vec2 vUv2;
        `],
        ["end", `
          vec4 cameraVideoTexel = texture2D(cameraVideoMap, vUv);
          vec4 faceLightTexel = texture2D(faceLightMap, vUv2);
          // gl_FragColor = cameraVideoTexel + faceLightTexel;
          gl_FragColor = cameraVideoTexel;
          gl_FragColor.a = 1.;
        `],
      ]
    });

    this._blurMaterial = new THREEShaderMaterial({
      transparent: true,
      uniforms: {
        blurDistance: [1, 1],
        blurTexture: 0,
      },
      vertexShaderChunks: [
        ["start", `
          varying vec2 vUv;
        `],
        ["end", `
          vUv = uv;
        `],
      ],
      fragmentShaderChunks: [
        ["start", `
          uniform vec2 blurDirection;
          uniform sampler2D blurTexture;

          varying vec2 vUv;

          ${BlurShader.blur()}
        `],
        ["end", `
          gl_FragColor = blur(blurTexture, vUv, vec2(32.), blurDirection);
        `],
        ["in vec2", "varying vec2"],
        [/texture\(/g, "texture2D("],
      ],
    });

    this._matCapMixMaterial = new THREEShaderMaterial({
      uniforms: {
        matCapTexture: MAT_CAP_TEXTURE,
        fakeMatCapRatio: .35,
      },
      vertexShaderChunks: [
        ["start", `
          varying vec2 vUv;
        `],
        ["main", `
          vUv = uv;
        `],
      ],
      fragmentShaderChunks: [
        ["start", `
          uniform sampler2D texture;
          uniform sampler2D matCapTexture;

          uniform float fakeMatCapRatio;

          varying vec2 vUv;
        `],
        ["end", `
          vec4 matCapTexel = texture2D(matCapTexture, (vUv * 2. - 1.) * .9 * .5 + .5);
          vec4 textureTexel = texture2D(texture, vUv);

          vec3 color = mix(textureTexel.rgb, matCapTexel.rgb, fakeMatCapRatio);

          gl_FragColor = vec4(color, 1.);
        `],
      ]
    });

    this._copyShaderMaterial = new THREEShaderMaterial({
      vertexShaderChunks: [
        ["start", `
          varying vec2 vUv;
        `],
        ["main", `
          vUv = uv;
        `],
      ],
      fragmentShaderChunks: [
        ["start", `
          uniform sampler2D texture;

          varying vec2 vUv;
        `],
        ["end", `
          gl_FragColor = texture2D(texture, vUv);
        `],
      ]
    });

    GUI.add({
      label: "Fake MatCap Ratio",
      object: this._matCapMixMaterial,
      key: "fakeMatCapRatio",
      folder: "💡 Lighting",
    });
  }

  get texture() {
    return this._renderTargetOut.texture;
  }

  update() {
    // this._renderer.setRenderTarget(this._renderTargetOut);
    // // this._renderer.setRenderTarget(null);
    // this._scene.add(this._quad);
    // this._quad.material = this._cameraVideoMapMaterial;
    // this._renderer.render(this._scene, this._camera);
    // this._scene.remove(this._quad);
    // [this._renderTargetIn, this._renderTargetOut] = [this._renderTargetOut, this._renderTargetIn];

    this._renderer.setRenderTarget(this._faceRenderTarget);
    // this._renderer.setRenderTarget(null);
    this._scene.add(this._quad);
    this._quad.material = this._extractFaceLightMaterial;
    this._renderer.render(this._scene, this._camera);
    this._scene.remove(this._quad);

    this._renderer.setRenderTarget(this._faceSmoothRenderTargetOut);
    this._scene.add(this._quad);
    this._quad.material = this._faceLightMapSmoothMaterial;
    this._quad.material.faceLightMap = this._faceRenderTarget.texture;
    this._quad.material.previousFaceLightMap = this._faceSmoothRenderTargetIn.texture;
    this._renderer.render(this._scene, this._camera);
    this._scene.remove(this._quad);
    [this._faceSmoothRenderTargetIn, this._faceSmoothRenderTargetOut] = [this._faceSmoothRenderTargetOut, this._faceSmoothRenderTargetIn];

    // this._renderer.setRenderTarget(null);
    // // this._renderer.setRenderTarget(this._renderTargetOut);
    // this._scene.add(this._sphere);
    // this._sphere.material = this._envMapMaterial;
    // this._sphere.material.faceLightMap = this._renderTargetIn.texture;
    // this._renderer.render(this._scene, this._camera);
    // this._scene.remove(this._sphere);
    // [this._renderTargetIn, this._renderTargetOut] = [this._renderTargetOut, this._renderTargetIn];

    this._scene.add(this._quad);
    const blurDistance = 1.5;
    this._quad.material = this._blurMaterial;
    const steps = 2;
    for (let index = 0; index < steps; index++) {
      this._renderer.setRenderTarget(this._renderTargetOut);
      this._quad.material.blurTexture = index === 0 ? this._faceSmoothRenderTargetIn.texture : this._renderTargetIn.texture;
      this._quad.material.blurDirection = [0, blurDistance];
      this._renderer.render(this._scene, this._camera);
      [this._renderTargetIn, this._renderTargetOut] = [this._renderTargetOut, this._renderTargetIn];

      this._renderer.setRenderTarget(this._renderTargetOut);
      this._quad.material.blurTexture = this._renderTargetIn.texture;
      this._quad.material.blurDirection = [blurDistance, 0];
      this._renderer.render(this._scene, this._camera);
      [this._renderTargetIn, this._renderTargetOut] = [this._renderTargetOut, this._renderTargetIn];
    }
    this._scene.remove(this._quad);

    this._renderer.setRenderTarget(this._renderTargetOut);
    this._scene.add(this._quad);
    this._quad.material = this._matCapMixMaterial;
    this._quad.material.texture = this._renderTargetIn.texture;
    this._renderer.render(this._scene, this._camera);
    [this._renderTargetIn, this._renderTargetOut] = [this._renderTargetOut, this._renderTargetIn];
    this._scene.remove(this._quad);

    this._renderer.setRenderTarget(null);
    this._scene.add(this._quad);
    this._quad.material = this._copyShaderMaterial;
    this._quad.material.texture = this._renderTargetOut.texture;
    this._renderer.render(this._scene, this._camera);
    [this._renderTargetIn, this._renderTargetOut] = [this._renderTargetOut, this._renderTargetIn];
    this._scene.remove(this._quad);
  }
}
